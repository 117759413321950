const industrySupportUrl = "https://www.covermymeds.com/main";
const industryUrl = "https://www.covermymeds.health";
const insightsUrl = "https://insights.covermymeds.com";
const experienceUrl = "https://experience.covermymeds.com/";

export const primaryLinkList = [
    {   
        "label": "Solutions", 
        "linkList": [   
            {
                "label": "The Patient Journey", 
                "href": `${industryUrl}/patient-journey`
            }, 
            {
                "label": "Access", 
                "href": `${industryUrl}/solutions/medication-access`
            }, 
            {
                "label": "Adherence" , 
                "href": `${industryUrl}/solutions/medication-adherence`
            },
            {
                "label": "Affordability", 
                "href": `${industryUrl}/solutions/medication-affordability`
            }
        ]
    },
    {   
        "label": "Insights", 
        "linkList": [   
            {
                "label": "News", 
                "href": `${insightsUrl}`
            }, 
            {
                "label": "Medication Access Report", 
                "href": `${insightsUrl}/medication-access-report`
            },
            {
                "label": "Press" , 
                "href": `${insightsUrl}/press`
            }
        ]
    },
    {   
        "label": "About", 
        "linkList": [   
            {
                "label": "Who We Are", 
                "href": `${industryUrl}/about`
            }, 
            {
                "label": "Events", 
                "href": `${industryUrl}/about/events`
            } 
        ]
    },
    {   
        "label": "Support", 
        "linkList": [   
            {
                "label": "FAQs", 
                "href": `${industrySupportUrl}/support`
            }, 
            {
                "label": "Contact", 
                "href": `${industrySupportUrl}/support/contact`
            }
        ]
    },
    {   
        "label": "Careers", 
        "linkList": [   
            {
                "label": "See Yourself Here", 
                "href": `${experienceUrl}`
            }, 
            {
                "label": "Open Positions", 
                "href": `${experienceUrl}/open-positions`
            }, 
            {
                "label": "Campus & Community", 
                "href": `${experienceUrl}/new-campus-and-community-impact`
            }
        ]
    }
];

export const secondaryLinkList = [
    {
        "label": "Log in", "href": "https://account.covermymeds.com"},
    {
        "label": "Enter Key", 
        "href": "https://portal.covermymeds.com/enter-key"
    },
    {
        "label": "Create An Account", 
        "href": "https://portal.covermymeds.com/signup", 
        "isCta": true
    }
]