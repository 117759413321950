
import { ApolloProvider } from '@apollo/react-hooks';
import App from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React, {useState} from 'react';
import AppContext from '../_app.context.jsx';
import {IndustryNavbar} from '../public/bundle.js';
import {primaryLinkList, secondaryLinkList} from '../data/nav';
import {apolloClient} from '../apollo/client';

import '../public/css/covermystyles.css';

const Content = ({children, pageProps}) => {

  const [isNavHidden, setIsNavHidden] = useState(!!pageProps?.hideNav);
  const [isFooterHidden, setIsFooterHidden] = useState(!!pageProps?.hideFooter);

  const appContextValue = {isNavHidden, isFooterHidden, setIsNavHidden, setIsFooterHidden}

  return (
    <AppContext.Provider value={appContextValue}>
        <IndustryNavbar
          linkList={primaryLinkList}
          secondaryLinkList={secondaryLinkList}
      />
      {children}
    </AppContext.Provider>
  )
}

class MyApp extends App {
  
  render() {
    const { Component, pageProps } = this.props;


    return (
      <ApolloProvider client={apolloClient}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, shrink-to-fit=no" />
          {/** Static Meta Info */}
          
        </Head>
        <Content>
          <Component {...pageProps} />
        </Content>

        {/** Data Layer Initiation */}
        <Script id="ga-inline-datalayer" strategy="beforeInteractive">
          {`window.dataLayer = window.dataLayer || [];`}
        </Script>

        {/* Google Analytics */}
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=UA-7780355-10"
          strategy="beforeInteractive"
        />
        
        {/* Google Tag Manager NEW */}
        <Script id="ga-inline-new" strategy="beforeInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-N7XK4K7V');`}
        </Script>
        
        {/* Google Tag Manager */}
        <Script
          id="ga-inline"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WDWQ49P');`}}
        ></Script>
      </ApolloProvider>
    );
  }
}

export default MyApp;
